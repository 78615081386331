:root {
  --primary: #f6522b;
  --white: #ffffff;
  --shade-900: #14141f;
  --shade-700: #21212c;
  --shade-500: #2e2e38;
  --shade-300: #505062;
  --shade-100: #ababba;

  --warning: #e73b64;
  --danger: #ff013c;
  --success: #06dcb5;
  --social-fb: #1558d6;
  --social-fb-text: rgba(132, 146, 166, 0.75);
  --default-if-bg: #eeeeee;

  --shade-900-95: rgba(20, 20, 31, 0.95);

  --primary-85: rgba(246, 82, 43, 0.85);
  --white-85: rgba(255, 255, 255, 0.85);
  --shade-900-85: rgba(20, 20, 31, 0.85);
  --shade-700-85: rgba(33, 33, 44, 0.85);
  --shade-500-85: rgba(46, 46, 56, 0.85);
  --shade-300-85: rgba(80, 80, 98, 0.85);
  --shade-100-85: rgba(171, 171, 186, 0.85);

  --primary-20: rgba(246, 82, 43, 0.2);
  --shade-100-20: rgba(171, 171, 186, 0.2);
  --white-20: rgba(255, 255, 255, 0.2);
  --shade-900-20: rgba(20, 20, 31, 0.2);
  --danger-20: rgba(255, 1, 60, 0.2);

  --primary-10: rgba(246, 82, 43, 0.1);
  --white-10: rgba(255, 255, 255, 0.1);

  --primary-50: rgba(246, 82, 43, 0.5);

  --black-24: rgba(0, 0, 0, 0.24);
  --black-18: rgba(0, 0, 0, 0.18);
  --black-16: rgba(0, 0, 0, 0.16);
  --black-10: rgba(0, 0, 0, 0.1);

  --shade-900-80: rgba(20, 20, 31, 0.8);

  --header-height: 56px;
  --editor-height: calc(100vh - 56px);
  --editor-wrapper-padding: 24px;
  --toolbar-height: 304px;
  --layer-button-height: 40px;
  --toolbar-layers-menu-offset: 40px;
}

html,
body,
#root {
  min-height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background: var(--shade-500);
  font-family: Heebo;
}

* {
  box-sizing: border-box;
}